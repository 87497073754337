try {
    jQuery.validator.setDefaults({
        highlight: function (element) {
            jQuery(element).closest('.form-group').addClass('has-danger').removeClass('has-success');
            jQuery(element).addClass('form-control-danger').removeClass('form-control-success');
        },
        unhighlight: function (element) {
            jQuery(element).removeClass('form-control-danger').addClass('form-control-success');
            if (jQuery(element).closest('.form-group').find('.form-control-danger').length == 0) {
                jQuery(element).closest('.form-group').removeClass('has-danger').addClass('has-success');
            }
        },
        errorPlacement: function (error, element) {
        }
    });
}
catch(exception){}
(function ($) {
    $.fn.onlyNumbers = function (options) {
        return this.each(function () {
            $(this).on('keydown', function (e) {
                // Allow: backspace, tab, enter, escape, and delete
                if (e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 13 || e.keyCode == 27 || e.keyCode == 46 ||
                        // Allow: Ctrl+A and Ctrl+C
                    (e.ctrlKey === true && (e.keyCode == 65 || e.keyCode == 67)) ||
                        // Allow: home, end, left, right
                    (e.keyCode >= 35 && e.keyCode <= 39)) {
                    // let it happen, don't do anything
                    return;
                } else {
                    // Ensure that it is a number and stop the keypress (192 for macintosh zero)
                    if (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105) && e.keyCode != 192) {
                        e.preventDefault();
                    }
                }
            });
        });
    };
})(jQuery);
$(document).ready(function () {
    /* tabs */
    $('#myTab').find('a').click(function (e) {
        e.preventDefault();
        $(this).tab('show')
    });
    $('#openmenu').on('click', function (e) {
        e.preventDefault;
        $('#menu').toggleClass('in');
    })
    NProgress.done();
});